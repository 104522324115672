/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-4/12 md:w-4/12 sm:w-5/5">
          <vx-card class="mb-4">
            <h3 class="font-bold mb-4">Extensions</h3>
            <vue-draggable-container id="wb0" class="wordBank">
              <h5 class="dragBlock font-semibold cursor-pointer ports-block" v-for="extension in extensions" :key="extension">
                {{ extension }}
              </h5>
            </vue-draggable-container>
          </vx-card>
          <vx-card class="mb-4">
            <h3 class="font-bold mb-4">Values</h3>
            <vue-draggable-container id="wb1" class="wordBank">
              <h5 class="dragBlock font-semibold cursor-pointer ports-block" v-for="value in values" :key="value">{{ value }}</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-8/12 md:w-8/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="fourth-sim pt-0 pb-0 pl-4">
              <div class="vx-row mb-4">
                <div class="vx-col w-6/12 flex items-center">
                  <h2 class="font-bold">Extensions</h2>
                </div>
                <div class="vx-col w-6/12 flex items-center">
                  <h2 class="font-bold">Values</h2>
                </div>
              </div>
              <div class="vx-row rounded-lg p-4 px-0 bg-light main-border mt-4">
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputExt0">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputVal0">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row rounded-lg p-4 px-0 bg-light main-border mt-4">
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputExt1">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputVal1">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row rounded-lg p-4 px-0 bg-light main-border mt-4">
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputExt2">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputVal2">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row rounded-lg p-4 px-0 bg-light main-border mt-4">
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputExt3">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputVal3">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row rounded-lg p-4 px-0 bg-light main-border mt-4">
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputExt4">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputVal4">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row rounded-lg p-4 px-0 bg-light main-border mt-4">
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputExt5">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputVal5">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row rounded-lg p-4 px-0 bg-light main-border mt-4">
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputExt6">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputVal6">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row rounded-lg p-4 px-0 bg-light main-border mt-4">
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputExt7">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputVal7">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row rounded-lg p-4 px-0 bg-light main-border mt-4">
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputExt8">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputVal8">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row rounded-lg p-4 px-0 bg-light main-border mt-4">
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputExt9">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg-light" id="inputVal9">
                  </vue-draggable-container>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import shuffle from 'shuffle-array';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,
      //last_drag_error: false,
      extensions: [
        'commonName (CN)',
        'subjAltName (SAN)',
        'extendedKeyUsage',
        'policyIdentifier',
        'Organization (O)',
        'Organization Unit (OU)',
        'Location (L)',
        'State (ST)',
        'Country (C)',
        'Private Key Type',
      ],
      values: [
        'api.intellectualpoint.com',
        'DNS Name=*.intellectualpoint.com',
        'serverAuth',
        'OCSP;URL=http://ocsp.pki.intellectualpoint.com',
        'Intellectual Point',
        'Information Technology',
        'Sterling',
        'Virginia',
        'USA',
        '2048-bit RSA',
      ],
      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },

      answers: [
        {
          extension: 'commonName (CN)',
          value: 'api.intellectualpoint.com',
        },
        {
          extension: 'subjAltName (SAN)',
          value: 'DNS Name=*.intellectualpoint.com',
        },
        {
          extension: 'extendedKeyUsage',
          value: 'serverAuth',
        },
        {
          extension: 'policyIdentifier',
          value: 'OCSP;URL=http://ocsp.pki.intellectualpoint.com',
        },
        {
          extension: 'Organization (O)',
          value: 'Intellectual Point',
        },
        {
          extension: 'Organization Unit (OU)',
          value: 'Information Technology',
        },
        {
          extension: 'Location (L)',
          value: 'Sterling',
        },
        {
          extension: 'State (ST)',
          value: 'Virginia',
        },
        {
          extension: 'Country (C)',
          value: 'USA',
        },
        {
          extension: 'Private Key Type',
          value: '2048-bit RSA',
        },
      ],
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wb0' && overContainer.id !== 'wb1' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          // this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      this.answers.forEach((item) => {
        for (let i = 0; i < 10; i++) {
          if (
            document.getElementById(`inputExt${i}`).textContent.trim() === item.extension &&
            document.getElementById(`inputVal${i}`).textContent.trim() === item.value
          ) {
            totalScore++;
            document.getElementById(`inputVal${i}`).style.borderColor = '#77FAAE';
            document.getElementById(`inputExt${i}`).style.borderColor = '#77FAAE';
            document.getElementById(`inputVal${i}`).style.backgroundColor = '#77FAAE';
            document.getElementById(`inputExt${i}`).style.backgroundColor = '#77FAAE';
          }
        }
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.extensions = shuffle(this.extensions);
    this.values = shuffle(this.values);
  },
};
</script>

<style scoped>
#wb0 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.6rem;
}

#wb1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.6rem;
}
</style>
